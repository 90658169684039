/**
* .db
*/ 

.db {

	display: block;

}

/**
* .dib
*/ 

.dib {

	display: inline-block;

}

/**
* .dn,
.hidden
*/ 

.dn,
.hidden {

	display: none;

}