/**
* .gallery--items
*/ 

.gallery--items {

	list-style: none;
	margin-bottom: 0;

}

/**
* .gallery--item
*/ 

.gallery--item {

	list-style: none;
	flex: 1;
	padding-left: 0;
	margin-bottom: 0;
	min-width: 50%;

	@include bp($tablet) {
		
		min-width: inherit;
		
	}

	&:before {
	
		display: none;

	}

	img {

		display: block;

	}

}