/**
* p
*/ 

p {

	font-weight: $fw-light;

	&:not(:last-of-type), &:not(.mn) {
	
		margin-bottom: $sp-medium;

	}

}

/**
* strong
*/ 

strong {

	font-weight: $fw-bold;

}