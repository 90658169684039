/**
* *
*/ 

*,
*::before,
*::after {

    margin: 0;
    padding: 0;
    box-sizing: inherit;
	line-height: $lh;

}

/**
* html
*/ 

html {

	margin: 0;
	padding: 0;
    height: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    text-size-adjust: 100%;
    font-size: 100%;

}

/**
* body
*/ 

body {

	margin: 0;
	padding: 0;
	background: $color-white;
	color: $color-grey;
	line-height: $lh;
	font-family: $ff;
	font-size: $fz;
	font-weight: $fw-normal;
	letter-spacing: 0.015rem;
	
}

/**
* ::selection
*/ 

::selection {

    background: $color-brand-one;
    color: $color-white;
    text-shadow: none;

}