/**
* .textbox
*/ 

.textbox {

	h1 {

		text-align: center;
	
	}

	*:last-child {

		margin-bottom: 0;

	}

}	