/**
* .review
*/ 

.review {

	margin-bottom: $sp-large;	
	position: relative;
	z-index: 0;

	&:not(:last-of-type) {

		&:after {

			content: '...';
			letter-spacing: 1rem;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			text-align: center;
			line-height: 0.25;
			font-size: 1.25rem;

		}

	}

}

/**
* .review--rating
*/ 

.review--rating {

	display: inline-block;
	vertical-align: middle;
	margin-left: $sp-small;
	
	svg {

		width: 20px;
		height: 20px;
		fill: #ffd100;

	}

}	

/**
* .review--content
*/ 

.review--content {

	font-style: italic;

	*:last-child {

		margin-bottom: 0;

	}	

}

/**
* .workshop--reviews
*/ 

.workshop--reviews {

	background: $color-off-white;

	.review {

		padding-bottom: 0

	}

}	