/**
* footer
*/ 

footer {

	background: $color-dark-grey;
	color: $color-white;
	text-align: center;

	@include bp($tablet) { 
	
		text-align: left;
	
	}

	/**
	* p
	*/ 
	
	p:last-of-type {
	
		margin-bottom: 0;
	
	}

	/**
	* li
	*/ 
	
	li {
	
		display: block;

		@include bp($tablet) {
			
			display: inline-block;
			
		}
	
	}

}

/**
* .footer--navigation
*/ 

.footer--navigation {

	margin-bottom: $sp-small;

	@include bp($tablet) { 
	
		text-align: left;
		margin-bottom: 0;
	
	}

}

/**
* .footer--copyright
*/ 

.footer--copyright {

	margin-bottom: $sp-medium;

	@include bp($tablet) { 
	
		margin-bottom: 0;
		text-align: right;
	
	}

}