/**
* article
*/ 

article {

	@include transition;
	margin-bottom: $sp-medium;

	@include bp($tablet) { 
	
		margin-bottom: 0;
	
	}

	&.shadow {

		box-shadow: 0 0 $sp-medium 0 rgba($color-black, 0.1);

		&:hover {

			box-shadow: 0 0 $sp-medium 0 rgba($color-black, 0.3);

		}

	}

	&.post--item {
	
		background: $color-white;

	}	

}

/**
* .post--item__content
*/ 

.post--item__content {

	padding-top: $sp-medium;
	padding-left: $sp-medium;
	padding-right: $sp-medium;

}

/**
* .post--item__thumb
*/ 

.post--item__thumb {

	position: relative;
	z-index: 0;

}