/**
* label
*/ 

label {

	font-size: $fz;
	color: $color-grey;
	font-weight: $fw-light;

}