/**
* .cssanimations
*/ 

.cssanimations {

	/**
	* .cookie--banner 
	*/ 
	
	.cookie--banner {
	
		opacity: 0;
		animation: fifl $timing-default $timing-slow ease-in-out forwards;
	
	}

}