/**
* section.workshops
*/ 

section.workshops {

	background: $color-off-white;

	&:before {

		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		max-height: $sp-extra-large * 3.33;
		height: 100%;
		background: $color-brand-one;

	}

	/**
	* &.active
	*/ 
	
	&.active {
	
		/**
		* .workshop--item
		*/ 
		
		.workshop--item {
		
			opacity: 1;
			transform: translateY(0);
				
		}
	
	}

}

/**
* .workshop--meta
*/ 

.workshop--meta {

	li {

		padding-left: 0;

		&:before {

			display: none;

		}

	}

	svg {

		width: $sp-small;
		height: $sp-small;
		display: inline-block;
		vertical-align: baseline;
		margin-right: $sp-extra-small;
		fill: $color-grey;

	}

}	

/**
* .workshop--item
*/ 

.workshop--item {

	opacity: 0;
	transform: translateY($sp-large);
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	height: 100%;

}

/**
* .workshops--cta
*/ 

.workshops--cta {

	color: $color-grey;
	display: inline-block;
	line-height: 1;

	svg {

		fill: lighten($color-grey, 10%);
		display: inline-block;
		vertical-align: middle;
		margin-left: $sp-extra-small;
		width: $sp-small;
		margin-top: -2.5px;
		height: auto;
		@include transition;

	}

	&:hover {

		text-decoration: none;
		color: $color-brand-one;

		svg {
		
			fill: $color-brand-one;

		}

	}

}

/**
* .workshop--holder
*/ 

.workshop--holder {

	margin-bottom: $sp-medium;

}

/**
* .workshop--single__meta
*/ 

.workshop--single__meta {

	background: $color-grey;

	&.fixed {
	
		position: relative;

	}

	@include bp($tablet) {
		
		position: fixed;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100%;
		
	}

	/**
	* ul
	*/ 
	
	ul {
	
		margin-bottom: 0;
		text-align: left;

		@include bp($tablet) {
			
			text-align: center;
			
		}

		li {

			display: block;
			color: $color-off-white;
			margin-left: $sp-medium;
			margin-right: $sp-medium;
			line-height: 20px;

			@include bp($tablet) {
				
				display: inline-block;
				
			}

			&:not(:last-child) {
	
				margin-bottom: $sp-medium;	

				@include bp($tablet) {
					
					margin-bottom: 0;
					
				}

			}

			svg {

				fill: $color-off-white;

			}

			a {

				line-height: inherit;

				/**
				* .workshop--meta__button
				*/ 

				&.workshop--meta__button {

					margin-bottom: 0;
					line-height: $sp-default-height * 1.15;
					display: none;

					@include bp($tablet) {
						
						display: block;
						
					}

				}

				svg {

					fill: $color-brand-one;

				}

			}

		}
	
	}

}

/**
* .sold--out
*/ 

.sold--out {

	background: #c53434;
	position: absolute;
	z-index: 0;
	top: $sp-small;
	right: $sp-small;
	color: $color-white;
	font-weight: $fw-normal;
	padding-left: $sp-extra-small;
	padding-right: $sp-extra-small;
	border-radius: 5px;
	display: inline-block;
	vertical-align: middle;
	font-size: 1rem;

}

/**
* .banner--item__title
*/ 

.banner--item__title {

	.sold--out {

		position: relative;
		top: 0;
		right: 0;

	}

}	

/**
* .note
*/ 

.note {

	padding-top: $sp-medium;	
	padding-bottom: $sp-medium;	

}

/**
* .note--title
*/ 

.note--title {

	font-size: $h3;
	font-style: italic;
	color: $color-neg;

}

/**
* .event--note
*/ 

.event--note {

	color: $color-neg;
	font-weight: $fw-bold;

}
