/**
* colors
*/ 

$color-brand-one: #95daac;
$color-brand-two: #a7d4e4;
$color-brand-three: #e1e6ef;

/**
* status
*/ 

$color-pos: #9cda94;
$color-neg: #da9494;

/**
* mono
*/ 

$color-white: #ffffff;
$color-off-white: darken($color-white, 5%);
$color-grey: #656565;
$color-light-grey: #dadada;
$color-dark-grey: darken($color-grey, 15%);
$color-black: #333333;