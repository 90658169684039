/**
* img
*/ 

img {
	
	width: 100%;
	max-width: 100%;
	display: block;
	height: auto;

	&[class*="wp-image"] {
	
		width: auto;
		margin: 0 auto;

	}

}

/**
* svg
*/ 

svg {

	width: $sp-medium;
	height: $sp-medium;

}