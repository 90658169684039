/**
* .wpcf7
*/ 

.wpcf7 {

	margin-top: $sp-medium;

	br {

		display: none;

	}

	.screen-reader-response {

		display: none;
	
	}	

}

/**
* .wpcf7-conditional
*/ 

.wpcf7-conditional {

	display: none;

	&.active {

		display: block;

	}

}	

/**
* .wpcf7-not-valid-tip
*/ 

.wpcf7-not-valid-tip {

	color: darken($color-neg, 10%);
	font-weight: $fw-normal;

}

/**
* .wpcf7-response-output
*/ 

.wpcf7-response-output {

	border-left: 3px solid transparent;
	padding-left: $sp-small;
	background: $color-off-white;
	padding-top: $sp-extra-small;
	padding-bottom: $sp-extra-small;
	font-weight: $fw-light;
	display: none;

	&.wpcf7-validation-errors {

		border-color: $color-neg;
		display: block;

	}

	&.wpcf7-mail-sent-ok {

		border-color: $color-pos;
		display: block;

	}

}