/**
* .mdt
*/ 

.mdt, .mdt--m {

	margin-top: $sp-medium;

}

/**
* .mdt--xs
*/ 

.mdt--xs {

	margin-top: $sp-extra-small;

}

/**
* .mdt--s
*/ 

.mdt--s {

	margin-top: $sp-small;

}

/**
* .mdt--l
*/ 

.mdt--l {

	margin-top: $sp-large;

}

/**
* .mdt--xl
*/ 

.mdt--xl {

	margin-top: $sp-extra-large;

}

/**
* .mr
*/ 

.mr {

	margin-right: $sp-medium;

}

/**
* .mdb
*/ 

.mdb, .mdb--m {

	margin-bottom: $sp-medium;

}

/**
* .mdb--xs
*/ 

.mdb--xs {

	margin-bottom: $sp-extra-small;

}

/**
* .mdb--s
*/ 

.mdb--s {

	margin-bottom: $sp-small;

}

/**
* .mdb--l
*/ 

.mdb--l {

	margin-bottom: $sp-large;

}

/**
* .mdb--xl
*/ 

.mdb--xl {

	margin-bottom: $sp-extra-large;

}

/**
* .ml
*/ 

.ml {

	margin-left: $sp-medium;

}

/**
* .ms
*/ 

.ms {

	margin: $sp-small;

}

/**
* .mm
*/ 

.mm {

	margin: $sp-medium;

}

/**
* .ml
*/ 

.ml {

	margin: $sp-large;

}

/**
* .mxl
*/ 

.mxl {

	margin: $sp-extra-large;

}

/**
* .mn
*/ 

.mn {

	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
	margin-left: 0;

}