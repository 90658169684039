/**
* .support--sections__item
*/ 

.support--sections__item {

	&.active .support--sections__anchor {

		border-left: 3px solid $color-brand-one;
		color: $color-brand-one;

	}

	&:hover .support--sections__anchor {

		border-left: 3px solid $color-brand-two;
		color: $color-grey;

	}

}

/**
* .support--sections__anchor
*/ 

.support--sections__anchor {

	background: $color-white;
	border-bottom: 1px solid $color-light-grey;
	cursor: pointer;
	border-left: 3px solid transparent;
	@include transition;
	display: block;
	width: 100%;
	color: $color-grey;

}

/**
* .support--content__item
*/ 

.support--content__item {

	background: $color-white;
	@include transition;
	display: none;

	&.active {
	
		display: block;

	}

	/**
	* correct titles
	*/ 

	h2,
	h3,
	h4,
	h5,
	h6 {

		display: block;
		margin-top: $sp-medium;
		margin-bottom: $sp-medium;
		text-decoration: underline;

	}

	/**
	* li
	*/ 
	
	li {
	
		list-style: disc;
		list-style-position: inside;

		h2,
		h3,
		h4,
		h5,
		h6 {

			display: inline-block;
			margin-top: 0;
			margin-bottom: $sp-small;
			text-decoration: none;

		}
	
	}

}