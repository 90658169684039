/************************************************************************
* padding top
************************************************************************/ 

/**
* .pdt
*/ 

.pdt, .pdt--m {

	padding-top: $sp-medium;

}

/**
* .pdt--xs
*/ 

.pdt--xs {

	padding-top: $sp-extra-small;

}

/**
* .pdt--s
*/ 

.pdt--s {

	padding-top: $sp-small;

}

/**
* .pdt--l
*/ 

.pdt--l {

	padding-top: $sp-large;

}

/**
* .pdt--xl
*/ 

.pdt--xl {

	padding-top: $sp-extra-large;

}

/************************************************************************
* padding bottom
************************************************************************/ 

/**
* .pdb
*/ 

.pdb, .pdb--m {

	padding-bottom: $sp-medium;

}

/**
* .pdb--xs
*/ 

.pdb--xs {

	padding-bottom: $sp-extra-small;

}

/**
* .pdb--s
*/ 

.pdb--s {

	padding-bottom: $sp-small;

}

/**
* .pdb--l
*/ 

.pdb--l {

	padding-bottom: $sp-large;

}

/**
* .pdb--xl
*/ 

.pdb--xl {

	padding-bottom: $sp-extra-large;

}

/************************************************************************
* padding left
************************************************************************/ 

/**
* .pdl
*/ 

.pdl, .pdl--m {

	padding-left: $sp-medium;

}

/**
* .pdl--xs
*/ 

.pdl--xs {

	padding-left: $sp-extra-small;

}

/**
* .pdl--s
*/ 

.pdl--s {

	padding-left: $sp-small;

}

/**
* .pdl--l
*/ 

.pdl--l {

	padding-left: $sp-large;

}

/**
* .pdl--xl
*/ 

.pdl--xl {

	padding-left: $sp-extra-large;

}

/************************************************************************
* padding right
************************************************************************/ 

/**
* .pdr
*/ 

.pdr, .pdr--m {

	padding-right: $sp-medium;

}

/**
* .pdr--xs
*/ 

.pdr--xs {

	padding-right: $sp-extra-small;

}

/**
* .pdr--s
*/ 

.pdr--s {

	padding-right: $sp-small;

}

/**
* .pdr--l
*/ 

.pdr--l {

	padding-right: $sp-large;

}

/**
* .pdr--xl
*/ 

.pdr--xl {

	padding-right: $sp-extra-large;

}

/************************************************************************
* statics
/************************************************************************/ 

/**
* .pl
*/ 

.pl {

	padding-left: $sp-medium;

}

/**
* .ps
*/ 

.ps {

	padding: $sp-small;

}

/**
* .pm
*/ 

.pm {

	padding: $sp-medium;

}

/**
* .pl
*/ 

.pl {

	padding: $sp-large;

}

/**
* .pxl
*/ 

.pxl {

	padding: $sp-extra-large;

}

/**
* .pn
*/ 

.pn {

	padding: 0;

}