/**
* .flex
*/ 

.flex {

	display: flex;
	flex-wrap: wrap;

}

/**
* .respond
*/ 

.respond {

	// flex-direction: column;

	@include bp($tablet) { 
	
		flex-direction: row;
	
	}

}

/**
* .justifyBetween
*/ 

.justifyBetween {

	justify-content: space-between; 

}

/**
* .justifyAround
*/ 

.justifyAround {

	justify-content: space-around; 

}

/**
* .justifyCenter
*/ 

.justifyCenter {

	justify-content: center; 

}

/**
* .alignCenter
*/ 

.alignCenter {

	align-items: center;

}