/**
* .social
*/ 

.social {

	border-top: 1px solid rgba($color-black, 0.1);

}

/**
* .social--title
*/ 

.social--title {

	text-align: center;
	text-transform: lowercase;
	letter-spacing: 0.15rem;

}

/**
* .social--item__link 
*/ 

.social--item__link {
	
	background: $color-grey;
	display: inline-block;
	margin-left: $sp-extra-small;
	margin-right: $sp-extra-small;
	border-radius: 100%;
	width: $sp-large;
	height: $sp-large;
	line-height: $sp-large;
	text-align: center;

	&:hover {

		background: $color-brand-one;

	}

	svg {

		fill: $color-white;
		display: inline-block;
		vertical-align: middle;

	}

}