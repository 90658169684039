/**
* .wrapper
*/ 

.wrapper {

	width: 90%;
	margin: 0 auto;
	
	/**
	* &.extra--small
	*/ 
	
	&.extra--small {
	
		max-width: $w-extra-small;
	
	}
	
	/**
	* &.small
	*/ 
	
	&.small {
	
		max-width: $w-small;
	
	}
	
	/**
	* &.medium
	*/ 
	
	&.medium {
	
		max-width: $w-medium;
	
	}
	
	/**
	* &.large
	*/ 
	
	&.large {
	
		max-width: $w-large;
	
	}

}

/**
* section
*/ 

section {

	position: relative;
	z-index: 1;
	background-color: $color-white;

}

/**
* header,
footer
*/ 

header,
footer {

	position: relative;
	z-index: 1;

}