/**
* Breakpoint mixin
*/

@mixin bp($breakpoint) {

    @media only screen and (min-width: $breakpoint + px ) { 

    	@content; 

    }
    
}