/**
* .post-navigation
*/ 

.post-navigation {

	background: $color-grey;
	padding-top: $sp-medium;
	padding-bottom: $sp-medium;

	h4 {

		color: $color-white;

	}

	/**
	* .nav-links
	*/ 
	
	.nav-links {
	
		@extend .wrapper;
		@extend .small;
		@extend .tac;
	
	}

	/**
	* .nav-previous,
	.nav-next
	*/ 
	
	.nav-previous,
	.nav-next {
		
		display: block;
		padding-left: $sp-small;
		padding-right: $sp-small;
		color: $color-brand-one;

		@include bp($tablet) { 
		
			display: inline-block;
		
		}
	
	}

}

/**
* .workshops-template-default
*/ 

.workshops-template-default {

	.post-navigation {

		background: $color-light-grey;

		h2 {

			text-align: center;

		}

		a {

			color: $color-grey;

		}

		.nav-links > div {
	
			display: block;
			margin-bottom: $sp-extra-small;

			@include bp($tablet) {
				
				margin-bottom: 0;
				display: inline-block;
				padding-left: $sp-small;
				padding-right: $sp-small;
				
			}

		}

	}

}