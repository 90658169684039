/**
* a
*/ 

a {

	text-decoration: none;
	@include transition;
	color: $color-brand-one;

	&:hover {

		text-decoration: underline;
	
	}

}

/**
* p a
*/ 

p a {

	font-weight: $fw-normal;

}