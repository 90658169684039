/**
* .services
*/ 

.services {

	background: $color-off-white;

}

/**
* .service
*/ 

.service {

	padding-top: $sp-extra-large * 1.33;
	padding-right: $sp-medium;
	padding-bottom: $sp-medium;
	padding-left: $sp-medium;
	background-position: center;
	background-size: cover;
	position: relative;	
	z-index: 0;

	&:before {
	
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(18,27,21,0.85) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(18,27,21,0.85) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(18,27,21,0.85) 100%);

	}

}

/**
* .service--title
*/ 

.service--title {

	color: $color-white;
	font-size: $h2;
	margin-bottom: $sp-small;

}

