/**
* input
*/ 

input {

	display: inline-block;
	width: 100%;
	font-size: $fz;
	outline: none;
	border: 1px solid $color-light-grey;
	height: $sp-default-height;
	line-height: $sp-default-height;
	padding-top: 1.5px;
	padding-left: $sp-small;
	padding-right: $sp-small;
	font-family: $ff;
	border-radius: 0px;
	box-shadow: none;

	&:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {

		padding-left: $sp-small;
		width: 100%;
		@include transition;

		&:hover {

			border: 1px solid $color-brand-one;
			box-shadow: 0 0 5px 0 rgba($color-brand-one, 0.33);

		}

		&:focus {

			border: 1px solid $color-brand-one;
			box-shadow: 0 0 5px 0 rgba($color-brand-one, 0.33);

		}

	}

	/**
	* checkbox
	*/ 

	&[type="checkbox"] {

		display: inline-block;
		vertical-align: initial;
		margin-right: $sp-extra-small;
		height: auto;
		width: auto;

	}

	/**
	* radio
	*/ 

	&[type="radio"] {

		display: inline-block;
		vertical-align: initial;
		margin-right: $sp-extra-small;
		height: auto;

	}

	&.wpcf7-not-valid {

		border-color: $color-neg;

	}

}