/**
* .icon--bars
*/ 

.icon--bars {

	position: relative;
	z-index: 1;
	width: 45px;
	height: 40px;
	display: inline-block;
	cursor: pointer;

	&.active { 
		
		.icon--bar {

			&:nth-of-type(1) { 

				top: 50%;
				margin-top: 0;
				transform: rotate(-45deg);

			}

			&:nth-of-type(2) { 

				top: 50%;
				transform: rotate(45deg);
				margin-top: 0;

			}

			&:nth-of-type(3) { 

				display: none;

			}

		}
	}

	@include bp($tablet) { 
		
		display: none;
	
	}

}

/**
* .icon--bar
*/ 

.icon--bar {

	position: absolute;
	z-index: 1;
	background: $color-white;
	height: 4px;
	width: calc(100% - 20px);
	border-radius: $sp-medium;
	transform-origin: center;
	@include transition;
	left: 10px;

	&:nth-of-type(1) { 

		top: 10px;

	}

	&:nth-of-type(2) { 

		top: 50%;
		transform: translateY(-50%);
	
	}

	&:nth-of-type(3) { 

		bottom: 10px;

	}

	@include bp($tablet) { 

		&:nth-of-type(1) { 

			top: 0px;

		}


		&:nth-of-type(3) { 

			bottom: 0px;

		}
	
		width: 100%;
		left: 0;
	
	}
	
}
