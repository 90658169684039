/**
* Breakpoints
*/

$mobile: 500;
$tablet: 800;
$desktop: 1000;
$max-wrapper: 1600;

/**
* widths
*/ 

$w-extra-small: 500px;
$w-small: 750px;
$w-medium: 1000px;
$w-large: 1400px;