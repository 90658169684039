/**
* fifb
*/

@keyframes fifb {

	0% {

		transform: translateY($sp-large);
		opacity: 0;

	}

	100% {

		transform: translateY(0);
		opacity: 1;

	}

}

/**
* fotb
*/

@keyframes fotb {

	0% {

		transform: translateY(0);
		opacity: 1;

	}

	100% {

		transform: translateY($sp-large);
		opacity: 1;

	}

}

/**
* fi
*/

@keyframes fi {

	0% {

		opacity: 0;

	}

	100% {

		opacity: 1;

	}

}

/**
* fifl
*/

@keyframes fifl {

	0% {

		opacity: 0;
		transform: translateX(- $sp-large);

	}

	100% {

		opacity: 1;
		transform: translateX(0);

	}

}

/**
* f0
*/

@keyframes fo {

	0% {

		opacity: 1;

	}

	100% {

		opacity: 0;

	}

}