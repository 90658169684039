/**
* .search--submit
*/ 

.search--submit {

	min-width: 175px;

	/**
	* svg
	*/ 
	
	svg {
	
		@include bp($tablet) { 
		
			margin-left: $sp-small;
		
		}
	
	}

}