/**
* fonts
*/ 
$ff: futura-pt, sans-serif;
$t-ff: futura-pt, sans-serif;

/**
* weight
*/ 

$fw-light: 200;
$fw-normal: 400;
$fw-bold: 500;
$fw-fat: 900;

/**
* sizes
*/ 

$fz: 18px;
$fz-s: 0.833rem;
$fz-l: 1.33rem;

/**
* explicit sizes for headings
*/ 

$h1: 1.75rem;
$h2: 1.5rem;
$h3: 1.4rem;
$h4: 1.1rem;
$h5: 1.05rem;
$h6: 1.05rem;