/**
* .cookie--banner
*/ 

.cookie--banner {

	position: fixed;
	z-index: 5;
	bottom: $sp-small;
	left: $sp-small;
	max-width: 400px;
	width: 92.5%;
	border-radius: 3px;
	background: rgba($color-white, 0.9);
	padding: $sp-medium;

	@include bp($mobile) { 
		
		bottom: $sp-large;
		left: $sp-large;
		width: 100%;
	
	}

	&.active {

		display: block !important;

	}

	&.fo {

		animation: fo ease-in-out 500ms forwards;

	}

}

/**
* .cookie--banner__wrapper
*/ 

.cookie--banner__wrapper {

	position: relative;
	z-index: 1;

	p {

		margin-bottom: 0;

	}

}

/**
* .cookie--banner__title
*/ 

.cookie--banner__title {

	font-weight: $fw-bold;
	color: $color-brand-one;
	font-size: $h3;

}

/**
* .cookie--banner__close
*/ 

.cookie--banner__close {

	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	font-size: $h1;

	&:hover {

		text-decoration: none;

	}

}