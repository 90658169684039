/**
* table
*/ 

table {

	border: none;
	border-collapse: collapse;
	margin-bottom: $sp-medium;
	width: 100%;

}

/**
* thead
*/ 

thead {

	display: none;

	@include bp($tablet) { 
	
	    display: table-header-group;
	
	}

}

/**
* th
*/ 

th {

	background: $color-light-grey;
	padding: $sp-extra-small;
	display: block;

	@include bp($tablet) { 
	
		display: table-cell;
	
	}

}

/**
* tr
*/ 

tr {

	display: block;
	margin-bottom: $sp-extra-small;
	width: 100%;
	border: 1px solid $color-light-grey;
	margin: -1px;

	@include bp($tablet) {

		display: table-row;
		
		&:nth-of-type(even) {

			background: $color-off-white;

		}

	}

}	

/**
* td
*/ 

td {

	border: none;
	vertical-align: text-top;
	padding: $sp-extra-small $sp-small;
	display: block;

	&:first-of-type {

		font-weight: $fw-normal;

	}

	@include bp($tablet) { 
	
		display: table-cell;
		border-right: 1px solid $color-light-grey;
	
	}

}