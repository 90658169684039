/**
* .bg
*/ 

*[class*="bg--"] {

	/**
	* &[class*="white"]
	*/ 
	
	&[class*="white"] {
	
		background: $color-white;
	
	}

	/**
	* &[class*="brand-two"]
	*/ 
	
	&[class*="brand-two"] {
	
		background: $color-brand-two;
	
	}

	/**
	* &[class*="brand-three"]
	*/ 
	
	&[class*="brand-three"] {
	
		background: $color-brand-three;
	
	}

}