/**
* spacers
*/ 

$lh: 30px;

/**
* used for margins
*/ 

$sp-extra-small: $lh / 4;
$sp-small: $lh / 2;
$sp-medium: $lh;
$sp-large: $lh * 2;
$sp-extra-large: $lh * 4;

/**
* heights
*/ 

$sp-default-height: $sp-medium * 1.33;