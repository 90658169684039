/**
* .pair--half
*/ 

.pair--half {

	&:first-of-type {

		margin-bottom: $sp-medium;

		@include bp(900) {
			
			margin-bottom: 0;
			padding-left: 0;
			
		}

	}

	&:last-of-type {

		@include bp(900) {
			
			padding-right: 0;
			
		}

	}

	& > *:last-child {

		margin-bottom: 0;

	}

}

/**
* .pair--content
*/ 

.pair--content {

	@include bp(900) {
		
		padding: $sp-medium;
	
	}

}

/**
* .pair--image
*/ 

.pair--image {

	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 250px;
	padding-bottom: 65%;

	@include bp(900) {
		
		height: auto;
		padding-bottom: 0;
		min-height: 520px;
		
	}

}

