/**
* .default--header
*/ 

.default--header {

	margin-top: $sp-large;

	p {

		font-size: $fz-l;

	}

}

/**
* .m--only
*/ 

.m--only {

	@include bp($tablet) {
		
		display: none;
		
	}

}

/**
* .loading--overlay.inactive
*/ 

.loading--overlay.inactive {

	animation: fo 250ms 125ms ease-in-out forwards;

}