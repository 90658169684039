/**
* .flexbox
*/ 

.flexbox {

	/**
	* .third
	*/ 

	.third {

		flex: 1;
		max-width: 100%;
		flex-basis: 100%;

		@include bp($tablet) { 

			flex: 0;
			max-width: 33.33%;
			flex-basis: 33.33%;

			&.wGutter {
			
				max-width: calc(33.33% - #{$sp-medium});
				flex-basis: calc(33.33% - #{$sp-medium});

			}
			
		}

	}

	/**
	* .half
	*/ 

	.half {

		flex: 1;
		max-width: 100%;
		flex-basis: 100%;

		@include bp(900) { 

			flex: 0;
			flex-basis: 50%;

			&.wGutter {
			
				max-width: calc(50% - #{$sp-medium});
				flex-basis: calc(50% - #{$sp-medium});

			}

		}

	}

	/**
	* .quarter
	*/ 

	.quarter {

		flex: 1;
		max-width: 100%;
		flex-basis: 100%;

		@include bp($tablet) { 

			flex: 0;
			max-width: 25%;
			flex-basis: 25%;

			&.wGutter {

				max-width: calc(25% - #{$sp-medium});
				flex-basis: calc(25% - #{$sp-medium});

			}

		}	

	}

	/**
	* .twoThirds
	*/ 

	.twoThirds {

		flex: 1;
		max-width: 100%;
		flex-basis: 100%;

		@include bp($tablet) { 

			flex: 0;
			max-width: 66.66%;
			flex-basis: 66.66%;
			
		}

	}

	/**
	* .full
	*/ 

	.full {

		flex: 1;
		max-width: 100%;
		flex-basis: 100%;

	}

}

/**
* .no-flexbox
*/ 

.no-flexbox {

	/**
	* .half
	*/ 
	
	.half {
	
		width: 100%;
		

		@include bp(900) { 

			&.wGutter {
			
				width: calc(50% - #{$sp-medium});

			}

		}
	
	}

}