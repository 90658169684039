/**
* .banner
*/ 

.banner {

	display: block;
	padding: 0;
	overflow: hidden;
	max-height: 98vh;

	br {

		display: none;

		@include bp($mobile) { 
		
			display: block;
		
		}

	}



}

/**
* .banner--item
*/ 

.banner--item {

	position: relative;
	z-index: 0;
	max-height: inherit;

	&:before {

		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(18,27,21,0.85) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(18,27,21,0.85) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(18,27,21,0.85) 100%);

	}

}

/**
* .banner--item__content
*/ 

.banner--item__content {

	position: absolute;
	z-index: 1;
	bottom: 10%;
	left: 50%;
	width: 100%;
	padding-left: $sp-small;
	padding-right: $sp-small;
	transform: translateX(-50%);

	@include bp($tablet) { 
	
		max-width: $w-medium;
		bottom: 12.5%;
		padding-left: 0;
		padding-right: 0;
	
	}

}

/**
* .banner--item__image
*/ 

.banner--item__image {

	will-change: margin-top;

}

/**
* .banner--item__title
*/ 

.banner--item__title {

	color: $color-white;
	text-align: center;
	font-size: $h1 * 1.2;
	line-height: 1.1;
	opacity: 0;
	animation: fifb $timing-default $timing-slow ease-in-out forwards;
	margin-bottom: $sp-small;
	font-weight: $fw-normal;

	@include bp($tablet) {
		
		font-size: calc(#{$h5} + 1.33vw);
		
	}

}

/**
* .banner--item__subCopy
*/ 

.banner--item__subCopy {

	color: $color-white;
	text-align: center;
	line-height: 1.4;
	opacity: 0;
	font-size: $fz-l;
	animation: fifb $timing-default $timing-slow * 2 ease-in-out forwards;
	margin-bottom: $sp-small;

	@include bp($tablet) {
		
		font-size: calc(#{$fz-s} + 0.5vw);
		
	}

}

/**
* .banner--item__anchor
*/ 

.banner--item__anchor {

	opacity: 0;
	animation: fifb $timing-default $timing-slow * 3 ease-in-out forwards;

}

