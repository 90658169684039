/**
* textarea
*/ 

textarea {

	display: block;
	font-size: $fz;
	font-family: inherit;
	outline: none;
	border: 1px solid $color-light-grey;
	max-height: $sp-extra-large;
	padding: $sp-small;
	margin-bottom: $sp-small;	
	border-radius: $sp-small;
	width: 100%;
	max-width: 100%;
	@include transition();
	border-radius: 0;
	box-shadow: none;

	&:hover {

		border: 1px solid $color-brand-one;
		box-shadow: 0 0 5px 0 rgba($color-brand-one, 0.33);

	}

	&:focus {

		border: 1px solid $color-brand-one;
		box-shadow: 0 0 5px 0 rgba($color-brand-one, 0.33);

	}

}