/**
* .triplet
*/ 

.triplet {

	.image-top {

		.triplet--image {

			margin-bottom: $sp-medium;

		}
	
	}

	.image-bottom {

		.triplet--content {

			margin-bottom: $sp-medium;

		}
	
	}

}

/**
* .triplet--item
*/ 

.triplet--item {

	margin-bottom: $sp-medium;

	@include bp($tablet) { 
	
		margin-bottom: 0;
	
	}

}